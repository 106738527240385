<template>
  <div class="mx-auto bg-about bg-no-repeat bg-cover bg-center flex justify-center items-center">
    <div class="container p-4 py-10 sm:p-40">
      <div class="grid grid-cols-1 gap-9 sm:grid-cols-2">
        <div class="px-3 py-8 bg-about-img bg-no-repeat">
          <img src="../assets/1.jpg" class="rounded-3xl cursor-pointer hover:bounceSlow">
        </div>
        <div class="flex justify-center items-center">
          <div>
            <h6 class="text-center sm:text-left text-default text-lg uppercase tracking-wider font-manrope font-semibold">CHÚNG TÔI LÀ AI</h6>
            <h2 class="text-center sm:text-left text-global-black text-6xl font-bold" data-aos="fade-up">123 Sao Network</h2>
            <div class="elementor-divider my-7"></div>
            <p class="text-center sm:text-left text-global-gray text-base font-manrope mb-5">Đơn vị tiên phong trong việc quản lý, khai thác và bảo vệ nội dung số trên các nền tảng mạng xã hội</p>
            <div class="flex mt-2">
              <svg aria-hidden="true" class="w-5 cursor-pointer fill-current text-global-red hover:text-default" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
              <span class="ml-3 leading-4 text-lg text-global-black font-manrope">Khai thác doanh thu trên mạng xã hội</span>
            </div>
            <div class="flex mt-2">
              <svg aria-hidden="true" class="w-5 cursor-pointer fill-current text-global-red hover:text-default" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
              <span class="ml-3 leading-4 text-lg text-global-black font-manrope">Kết nối các nhãn hàng với nghệ sĩ</span>
            </div>
            <div class="flex mt-2">
              <svg aria-hidden="true" class="w-5 cursor-pointer fill-current text-global-red hover:text-default" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
              <span class="ml-3 leading-4 text-lg text-global-black font-manrope">Tư vấn định hướng xây dựng nội dung</span>
            </div>
            <div class="flex mt-2">
              <svg aria-hidden="true" class="w-5 cursor-pointer fill-current text-global-red hover:text-default" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
              <span class="ml-3 leading-4 text-lg text-global-black font-manrope">Hỗ trợ miễn phí studio livestream</span>
            </div>
            <div class="flex mt-2">
              <svg aria-hidden="true" class="w-5 cursor-pointer fill-current text-global-red hover:text-default" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
              <span class="ml-3 leading-4 text-lg text-global-black font-manrope">Bảo vệ bản quyền toàn diện nội dung của bạn</span>
            </div>
            <div class="flex mt-2">
              <svg aria-hidden="true" class="w-5 cursor-pointer fill-current text-global-red hover:text-default" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
              <span class="ml-3 leading-4 text-lg text-global-black font-manrope">Xử lý các vấn đề nền tảng, xác minh danh tính</span>
            </div>
            <div class="flex mt-2">
              <svg aria-hidden="true" class="w-5 cursor-pointer fill-current text-global-red hover:text-default" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
              </svg>
              <span class="ml-3 leading-4 text-lg text-global-black font-manrope">Phân phối, phát hành nhạc lên đa nền tảng</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'about'
}
</script>
<style scoped>
</style>