<template>
  <div class="relative flex justify-center items-center bg-social-img py-40 px-5 sm:px-20">
    <div class="absolute w-full h-full top-0 left-0 bg-social-block bg-op-black bg-cover"></div>
    <div class="relative w-full grid grid-cols-2 gap-2 sm:grid-cols-4">
      <div class="text-center">
        <div class="p-5 w-20 h-20 sm:w-40 sm:h-40 bg-global-red rounded-full mx-auto flex justify-center items-center">
          <img class="cursor-pointer hover:bounceIdle" src="../assets/Satisified-clients-icon-qk2kvr8zn0wtxnmy5yxrlincjrsqga2hop6toh9q3y.png">
        </div>
        <div class="font-manrope text-white mt-2 text-5xl font-bold">94+</div>
        <div class="font-manrope text-cream text-sm mt-2">Nhà sáng tạo nội dung</div>
      </div>
      <div class="text-center">
        <div class="p-5 w-20 h-20 sm:w-40 sm:h-40 bg-default rounded-full mx-auto flex justify-center items-center">
          <img class="cursor-pointer hover:bounceIdle" src="../assets/Projects-completed-icon-qk2kvpdb9cu9afpogy4igj4fd0200vv10fvupxcige.png">
        </div>
        <div class="font-manrope text-white mt-2 text-5xl font-bold">200+</div>
        <div class="font-manrope text-cream text-sm mt-2">Fanpage/ Nhóm/ Kênh</div>
      </div>
      <div class="text-center">
        <div class="p-5 w-20 h-20 sm:w-40 sm:h-40 bg-global-red rounded-full mx-auto flex justify-center items-center">
          <img class="cursor-pointer hover:bounceIdle" src="../assets/Team-members-icon-qk2kvt4o0ozekvk7uzr0qi69qjjgvo9ycyhsn16xri.png">
        </div>
        <div class="font-manrope text-white mt-2 text-5xl font-bold">250+</div>
        <div class="font-manrope text-cream text-sm mt-2">Đối tác nhãn hàng</div>
      </div>
      <div class="text-center">
        <div class="p-5 w-20 h-20 sm:w-40 sm:h-40 bg-default rounded-full mx-auto flex justify-center items-center">
          <img class="cursor-pointer hover:bounceIdle" src="../assets/save-money-1-qk2n7grk9vi3vi1nd5jone75137hxwdxv0pi9l5jzi.png">
        </div>
        <div class="font-manrope text-white mt-2 text-5xl font-bold">768$</div>
        <div class="font-manrope text-cream text-sm mt-2">Trung bình doanh thu/creator</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'social'
}
</script>
<style scoped>
</style>