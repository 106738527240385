<template>
  <div class="container block sm:flex m-auto pt-10">
    <div class="w-full sm:w-3/4 p-5">
      <h1 class="font-manrope text-global-black text-2xl font-bold">CÔNG TY TNHH PERA SOLUTIONS</h1>
      <p class="font-manrope text-global-gray">Địa chỉ: Số 60, đường số 05, khu dân cư Him Lam, phường Tân Hưng, Quận 7, TP.HCM</p>
      <p class="font-manrope text-global-gray">Số điện thoại: 0559296508</p>
      <p class="font-manrope text-global-gray">Email: partner@123sao.vn</p>
    </div>
    <div class="w-full sm:w-1/4">
      <a href="/">
        <img class="cursor-pointer w-40 h-40 m-auto" src="../assets/123_Sao_network_Logo-1.png">
      </a>
    </div>
  </div>
  <div class="font-manrope text-global-gray text-center my-5">Copyright © 123Sao 2024. All rights reserved</div>
</template>
<script>
export default {
  name: 'footer'
}
</script>
<style scoped>
</style>