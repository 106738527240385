<template>
  <div class="block sm:flex relative">
    <div class="w-full sm:w-1/3 bg-fleft-img bg-no-repeat bg-cover bg-center" style="height: 650px;"></div>
    <div class="w-full sm:w-2/3 bg-fright-img bg-no-repeat bg-cover bg-center" style="height: 650px;"></div>
    <div class="absolute w-full h-full top-0 left-0 py-10 flex justify-center items-center">
      <div class="block sm:flex p-4 w-full sm:w-4/5">
        <div class="relative w-full sm:w-1/3 rounded-3xl bg-no-repeat bg-cover bg-center bg-service-img  flex justify-center items-center">
          <div class="absolute w-full h-full top-0 left-0 rounded-3xl bg-global-red bg-opacity-80"></div>
          <div class="relative text-center p-10 sm:p-16">
            <h2 class="text-white font-semibold text-lg">Chúng tôi cung cấp</h2>
            <h2 class="text-white text-5xl font-bold">Dịch vụ</h2>
            <div class="elementor-divider-white my-4"></div>
            <p class="text-white text-lg sm:text-left">Hợp tác với 123 Sao Network bạn sẽ được gì?</p>
          </div>
        </div>
        <div class="w-full sm:w-2/3 px-5 grid grid-cols-1 gap-2 sm:grid-cols-4 mt-5 sm:mt-0">
          <div class="flex justify-center items-center">
            <div class="p-1 text-center">
              <svg aria-hidden="true" class="w-14 h-14 m-auto" viewBox="0 0 288 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"></path>
              </svg>
              <h2 class="text-2xl font-bold h-20 mt-2">Tối ưu doanh thu</h2>
              <p class="font-manrope text-global-gray h-20 sm:h-40">Tạo doanh thu từ nội dung của bạn trên các nền tảng mạng xã hội hiện nay như Facebook, Tiktok, Youtube</p>
            </div>
          </div>
          <div class="flex justify-center items-center">
            <div class="p-1 text-center">
              <svg aria-hidden="true" class="w-14 h-14 m-auto" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 448c-110.532 0-200-89.451-200-200 0-110.531 89.451-200 200-200 110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200zm107.351-101.064c-9.614 9.712-45.53 41.396-104.065 41.396-82.43 0-140.484-61.425-140.484-141.567 0-79.152 60.275-139.401 139.762-139.401 55.531 0 88.738 26.62 97.593 34.779a11.965 11.965 0 0 1 1.936 15.322l-18.155 28.113c-3.841 5.95-11.966 7.282-17.499 2.921-8.595-6.776-31.814-22.538-61.708-22.538-48.303 0-77.916 35.33-77.916 80.082 0 41.589 26.888 83.692 78.277 83.692 32.657 0 56.843-19.039 65.726-27.225 5.27-4.857 13.596-4.039 17.82 1.738l19.865 27.17a11.947 11.947 0 0 1-1.152 15.518z"></path>
              </svg>
              <h2 class="text-2xl font-bold h-20 mt-2">Bảo vệ bản quyền</h2>
              <p class="font-manrope text-global-gray h-20 sm:h-40">Bảo vệ ngăn chặn hoặc khai thác doanh thu từ nội dung của bạn khi có người khác đăng tải lại trên các MXH</p>
            </div>
          </div>
          <div class="flex justify-center items-center">
            <div class="p-1 text-center">
              <svg aria-hidden="true" class="w-14 h-14 m-auto" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M416 192c0-88.37-93.12-160-208-160S0 103.63 0 192c0 34.27 14.13 65.95 37.97 91.98C24.61 314.22 2.52 338.16 2.2 338.5A7.995 7.995 0 0 0 8 352c36.58 0 66.93-12.25 88.73-24.98C128.93 342.76 167.02 352 208 352c114.88 0 208-71.63 208-160zm-224 96v-16.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V96c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v16.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07V288c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8zm346.01 123.99C561.87 385.96 576 354.27 576 320c0-66.94-53.49-124.2-129.33-148.07.86 6.6 1.33 13.29 1.33 20.07 0 105.87-107.66 192-240 192-10.78 0-21.32-.77-31.73-1.88C207.8 439.63 281.77 480 368 480c40.98 0 79.07-9.24 111.27-24.98C501.07 467.75 531.42 480 568 480c3.2 0 6.09-1.91 7.34-4.84 1.27-2.94.66-6.34-1.55-8.67-.31-.33-22.42-24.24-35.78-54.5z"></path>
              </svg>
              <h2 class="text-2xl font-bold h-20 mt-2">Kết nối nhãn hàng</h2>
              <p class="font-manrope text-global-gray h-20 sm:h-40">Kết nối nhãn hàng với nghệ sĩ để tạo ra các chiến dịch tiếp thị, mang lại doanh thu. Miễn phí 100% kĩ thuật, studio livestream</p>
            </div>
          </div>
          <div class="flex justify-center items-center">
            <div class="p-1 text-center">
              <svg aria-hidden="true" class="w-14 h-14 m-auto" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="M144 208c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"></path>
              </svg>
              <h2 class="text-2xl font-bold h-20 mt-2">Hỗ trợ</h2>
              <p class="font-manrope text-global-gray h-20 sm:h-40">Hỗ trợ 24/7 các vấn đề liên quan đến việc hợp tác hoặc tư vấn các chính sách mới của nền tảng cho đối tác</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'service'
}
</script>
<style scoped>
</style>