<template>
  <div>
    <div class="mx-auto bg-top-banner bg-no-repeat bg-cover bg-center">
      <div class="relative w-full py-40 sm:52 bg-black bg-opacity-50 bg-top-banner-cover bg-no-repeat bg-cover bg-center flex justify-center items-center aspect-video">
        <div>
          <h6 class="text-center text-default text-lg uppercase tracking-wider font-manrope font-semibold">Social Multi Channel Network</h6>
          <h1 class="text-center text-white text-7xl font-bold" data-aos="fade-up">123 Sao Network</h1>
          <p class="text-center text-white mt-5 text-xl">Bảo vệ bản quyền và tạo thu nhập từ nội dung của bạn trên</p>
          <p class="text-center text-white text-xl">các nền tảng mạng xã hội </p>
          <div class="grid grid-cols-2 w-96 m-auto">
            <a class="m-auto mt-8 h-14 w-40 leading-14 rounded-3xl text-center text-white bg-default text-lg" href="https://www.facebook.com/123saomcn" target="_blank">
              Fanpage
              <font-awesome-icon class="ml-2" icon="fa-solid fa-arrow-right" />
            </a>
            <a class="m-auto mt-8 h-14 w-40 leading-14 rounded-3xl text-center text-white border border-white border-solid bg-black text-lg" href="mailto:partner@123sao.vn">
              Email
              <font-awesome-icon class="ml-2" icon="fa-solid fa-arrow-right" />
            </a>
          </div>
        </div>
        <img src="../assets/scroll_down_image.png" class="absolute bottom-8 cursor-pointer hover:bounceNew">
      </div>
    </div>
    <div class="container p-4 mx-auto">
      <h1 class="text-2xl font-bold">{{ news.title }}</h1>
      <div class="flex mt-4">
        <img :src="news.author?.avatar?.url" class="rounded-full w-12 h-12 object-cover">
        <div class="ml-4">
          <div class="font-bold">{{news.author?.name}}</div>
          <div class="text-global-gray">{{news.author?.description}}</div>
        </div>
      </div>
      <div class="mt-4 html-content" v-html="news.content" /> 
    </div>
    <div class="mt-10">
      <h2 class="text-center text-default text-2xl font-bold">Tin liên quan</h2>
      <div class="px-2 overflow-hidden w-full sm:w-10/12 mx-auto sm:mt-6">
        <Carousel v-bind="settings" :breakpoints="breakpoints">
          <Slide v-for="(_news, idx) in listNews" :key="idx">
            <div class="rounded-2xl bg-white p-2 sm:p-2">
              <a :href="`/news/${_news.slug}`">
                <img :src="`${_news.thumb?.url}`" class="mx-auto rounded-2xl object-cover cursor-pointer w-40 sm:w-48 h-40 sm:h-48">
              </a>
              <a :href="`/news/${_news.slug}`">
                <h3 class="text-center sm:text-justify text-global-black text-sm sm:text-sm font-bold mt-4 w-40 sm:w-40 h-40 sm:h-48">{{ _news.title }}</h3>
              </a>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
    <foot />
  </div>
</template>
<script>
import foot from '@/components/footer.vue'
import { Carousel, Slide } from 'vue3-carousel'
const hostApi = 'https://apis.123sao.vn'

export default {
  name: 'News',
  components: {
    foot,
    Carousel,
    Slide
  },
  data: () => ({
    news: {},
    listNews: [],
    settings: {
      itemsToShow: 2,
      itemsToScroll: 1,
      wrapAround: true,
      transition: 500,
      autoplay: 5000,
      snapAlign: "start"
    },
    breakpoints: {
      700: {
        itemsToShow: 5,
        itemsToScroll: 1,
        snapAlign: "center"
      },
    }
  }),
  async mounted() {
    let slug = this.$route.params.id
    if (slug)
      this.getNews(slug)
  },
  methods: {
    async getNews(slug) {
      const response = await fetch(`${hostApi}/app/articles/${slug}`);
      const result = await response.json();
      if (result && result.id) {
        this.news = result
        this.getListNews(result.id)
      }
    },
    async getListNews(id) {
      const response = await fetch(`${hostApi}/app/articles/${id}/similar`);
      const result = await response.json();
      if (result && result.sectors && result.sectors.length > 0) {
        this.listNews = result.sectors
      }
    },
  }
}
</script>
<style scoped>
</style>