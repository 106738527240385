<template>
  <div>
    <div class="mx-auto bg-top-banner bg-no-repeat bg-cover bg-center">
      <div class="relative w-full min-h-90 bg-black bg-opacity-50 bg-top-banner-cover bg-no-repeat bg-cover bg-center flex justify-center items-center aspect-video">
        <div>
          <h6 class="text-center text-default text-lg uppercase tracking-wider font-manrope font-semibold">Social Multi Channel Network</h6>
          <h1 class="text-center text-white text-7xl font-bold" data-aos="fade-up">123 Sao Network</h1>
          <p class="text-center text-white mt-5 text-xl">Bảo vệ bản quyền và tạo thu nhập từ nội dung của bạn trên</p>
          <p class="text-center text-white text-xl">các nền tảng mạng xã hội </p>
          <div class="grid grid-cols-2 w-96 m-auto">
            <a class="m-auto mt-8 h-14 w-40 leading-14 rounded-3xl text-center text-white bg-default text-lg" href="https://www.facebook.com/123saomcn" target="_blank">
              Fanpage
              <font-awesome-icon class="ml-2" icon="fa-solid fa-arrow-right" />
            </a>
            <a class="m-auto mt-8 h-14 w-40 leading-14 rounded-3xl text-center text-white border border-white border-solid bg-black text-lg" href="mailto:partner@123sao.vn">
              Email
              <font-awesome-icon class="ml-2" icon="fa-solid fa-arrow-right" />
            </a>
          </div>
        </div>
        <img src="../assets/scroll_down_image.png" class="absolute bottom-8 cursor-pointer hover:bounceNew">
      </div>
    </div>
    <about />
    <service />
    <social />
    <partner />
    <news />
    <foot />
  </div>
</template>
<script>
import about from '@/components/about.vue'
import service from '@/components/service.vue'
import social from '@/components/social.vue'
import partner from '@/components/partner.vue'
import news from '@/components/news.vue'
import foot from '@/components/footer.vue'

export default {
  name: 'Home',
  components: {
    about,
    service,
    social,
    partner,
    news,
    foot
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>