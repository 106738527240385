<template>
  <div class="relative bg-fright-img bg-no-repeat bg-cover bg-center flex justify-center items-center py-20">
    <div class="absolute w-full h-full top-0 left-0 bg-black bg-opacity-10"></div>
    <div class="relative w-full overflow-hidden">
      <h6 class="text-center text-default text-lg tracking-wider font-semibold">Tin tức</h6>
      <h2 class="text-center text-global-black text-3xl font-bold m-auto w-full px-2" style="max-width:570px;">Các tin tức mới nhất của chúng tôi</h2>
      <div class="elementor-divider-center my-5"></div>
      <div class="px-2 overflow-hidden w-full sm:w-10/12 mx-auto">
        <Carousel v-bind="settings" :breakpoints="breakpoints">
          <Slide v-for="(_news, idx) in listNews" :key="idx">
            <div class="rounded-2xl bg-white p-4 sm:p-4">
              <a :href="`/news/${_news.slug}`">
                <img :src="`${_news.thumb?.url}`" class="mx-auto rounded-2xl object-cover cursor-pointer w-80 sm:w-80 h-80 sm:h-80">
              </a>
              <a :href="`/news/${_news.slug}`">
                <h3 class="text-center text-global-black text-sm sm:text-xl font-bold mt-4 w-80 sm:w-80">{{ _news.title }}</h3>
              </a>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
const hostApi = 'https://apis.123sao.vn'
export default {
  name: 'news',
  components: {
    Carousel,
    Slide
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      itemsToScroll: 1,
      wrapAround: true,
      transition: 500,
      autoplay: 5000,
      snapAlign: "start"
    },
    breakpoints: {
      700: {
        itemsToShow: 3,
        itemsToScroll: 1,
        snapAlign: "center"
      },
    },
    listNews: []
  }),
  async mounted() {
    this.getListNews()
  },
  methods: {
    async getListNews() {
      const response = await fetch(`${hostApi}/app/articles?_start=0&_limit=10&_sort=priority%3ADESC%2Ccreated_at%3ADESC%2Cviews_today%3ADESC%2Cviews%3ADESC`);
      const result = await response.json();
      if (result && result.length > 0) {
        this.listNews = result
      }
    },
  }
}
</script>
<style scoped>
.example-slide {
  align-items: center;
  background-color: #666;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  min-height: 10rem;
}
</style>