<template>
  <div class="relative bg-fright-img bg-no-repeat bg-cover bg-center flex justify-center items-center py-20">
    <div class="absolute w-full h-full top-0 left-0 bg-black bg-opacity-10"></div>
    <div class="relative w-full overflow-hidden">
      <h6 class="text-center text-default text-lg tracking-wider font-semibold">Đối tác</h6>
      <h2 class="text-center text-global-black text-3xl font-bold m-auto w-full px-2" style="max-width:570px;">Các nhà sáng tạo nội dung tiêu biểu của chúng tôi</h2>
      <div class="elementor-divider-center my-5"></div>
      <div class="px-2 overflow-hidden w-full sm:w-10/12 mx-auto">
        <Carousel v-bind="settings" :breakpoints="breakpoints">
          <Slide :key="1">
            <div class="rounded-3xl bg-white p-3 sm:p-8">
              <img src="../assets/1-1.jpg" class="mx-auto rounded-3xl cursor-pointer w-36 sm:w-80">
              <h3 class="text-center text-global-black text-xl font-bold mt-2 whitespace-nowrap">Cao Minh Đạt</h3>
              <div class="text-center text-global-gray text-lg mt-2">Diễn viên</div>
            </div>
          </Slide>
          <Slide :key="2">
            <div class="rounded-3xl bg-white p-3 sm:p-8">
              <img src="../assets/347838143_729266182311672_6891224726663259162_n.png" class="mx-auto rounded-3xl cursor-pointer w-36 sm:w-80">
              <h3 class="text-center text-global-black text-xl font-bold mt-2 whitespace-nowrap">Thánh Riviu</h3>
              <div class="text-center text-global-gray text-lg mt-2">Creative Company</div>
            </div>
          </Slide>
          <Slide :key="3">
            <div class="rounded-3xl bg-white p-3 sm:p-8">
              <img src="../assets/316814621_593436559252584_1400357514498599501_n.jpg" class="mx-auto rounded-3xl cursor-pointer w-36 sm:w-80">
              <h3 class="text-center text-global-black text-xl font-bold mt-2 whitespace-nowrap">Huỳnh Tuấn Khanh</h3>
              <div class="text-center text-global-gray text-lg mt-2">Video Creator</div>
            </div>
          </Slide>
          <Slide :key="4">
            <div class="rounded-3xl bg-white p-3 sm:p-8">
              <img src="../assets/337558764_160828886848500_306593990716230618_n.jpg" class="mx-auto rounded-3xl cursor-pointer w-36 sm:w-80">
              <h3 class="text-center text-global-black text-xl font-bold mt-2 whitespace-nowrap">Thư Đan Nguyễn</h3>
              <div class="text-center text-global-gray text-lg mt-2">Fashionista</div>
            </div>
          </Slide>
          <Slide :key="5">
            <div class="rounded-3xl bg-white p-3 sm:p-8">
              <img src="../assets/413880024_918321199663418_3778937159725662139_n.jpg" class="mx-auto rounded-3xl cursor-pointer w-36 sm:w-80">
              <h3 class="text-center text-global-black text-xl font-bold mt-2 whitespace-nowrap">Phạm Trưởng</h3>
              <div class="text-center text-global-gray text-lg mt-2">Ca sĩ</div>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
export default {
  name: 'partner',
  components: {
    Carousel,
    Slide
  },
  data: () => ({
    settings: {
      itemsToShow: 2,
      itemsToScroll: 1,
      wrapAround: true,
      transition: 500,
      autoplay: 5000,
      snapAlign: "start"
    },
    breakpoints: {
      700: {
        itemsToShow: 3,
        itemsToScroll: 1,
        snapAlign: "center"
      },
    },
  }),
  // async mounted() {
  //   this.isMobile = this.checkMobile()
  //   console.log(this.isMobile)
  // },
  // methods: {
  //   checkMobile() {
  //     if (!process.server) {
  //       if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  //         return true
  //       } else {
  //         return false
  //       }
  //     }
  //   },
  // }
}
</script>
<style scoped>
.example-slide {
  align-items: center;
  background-color: #666;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  min-height: 10rem;
}
</style>