import { createApp } from 'vue'
import { createRouter, createWebHistory  } from 'vue-router'
import App from './App.vue'
import HomeComponent from '@/pages/home.vue'
import NewsComponent from '@/pages/news.vue'
import './assets/tailwind.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas )
const router = createRouter({
	history: createWebHistory(),
	routes: [
		{ path: '/', name: 'Home', component: HomeComponent },
		{ path: '/:id', name: 'New', component: NewsComponent },
		{ path: '/news/:id', name: 'News', component: NewsComponent }
	],
	strict: true
})
const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.mount('#app')

